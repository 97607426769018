.good-job-box {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 122010;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: transparent;
    text-align: center;
    width: auto;
    height: auto;
}

.blob {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 250%; /* Increase width to fit content */
    height: 250%; /* Increase height to fit content */
    z-index: -1;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1)); /* Drop shadow */
    transform: translate(-50%, -50%);
    animation: blob-jiggle-1 5s infinite ease-in-out, blob-jiggle-2 9s infinite ease-in-out;
    transform-origin: center;
    perspective: 1000px;
}

@keyframes blob-jiggle-1 {
    0%, 100% {
        transform: translate(-50%, -50%) scale(1) rotate(0deg);
    }
    25% {
        transform: translate(-52%, -48%) scale(1.05) rotate(3deg);
    }
    50% {
        transform: translate(-50%, -50%) scale(0.95) rotate(-3deg);
    }
    75% {
        transform: translate(-48%, -52%) scale(1.1) rotate(3deg);
    }
}

@keyframes blob-jiggle-2 {
    0%, 100% {
        transform: translate(-50%, -50%) scale(1) rotate(0deg);
    }
    20% {
        transform: translate(-51%, -49%) scale(1.03) rotate(2deg);
    }
    40% {
        transform: translate(-49%, -51%) scale(1.07) rotate(-2deg);
    }
    60% {
        transform: translate(-52%, -48%) scale(0.98) rotate(1deg);
    }
    80% {
        transform: translate(-48%, -52%) scale(1.02) rotate(-1deg);
    }
}

.good-job-box-content {
    position: relative;
    z-index: 1; /* Ensure the text is above the blob */
    color: #FFFFFF; /* Lighter text color */
}
