.tutorial-box {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 125029;
    padding: 15px;
    background-color: #f9f9f9;
    border: 2px solid #2196f3;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: opacity 0.5s, transform 0.5s;
    opacity: 0;
    transform: scale(0.9);
}

.tutorial-box.visible {
    opacity: 1;
    transform: scale(1);
    animation: glow-jiggle 1s infinite alternate;
}


@keyframes glow-jiggle {
    0% {
        box-shadow: 0px 0px 10px rgba(33, 150, 243, 0.5);
        transform: translateY(0);
    }
    100% {
        box-shadow: 0px 0px 20px rgba(33, 150, 243, 1);
        transform: translateY(-5px);
    }
}













.good-job-box {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 122010;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: transparent;
    text-align: center;
    width: auto;
    height: auto;
}

.blob {
    position: absolute;
    top: 0;
    left: 0;
    width: 110%; /* Adjust width to fit content */
    height: 110%; /* Adjust height to fit content */
    fill: #e0f7fa;
    z-index: -1;
    
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1)); /* Drop shadow */
    border-radius: 50%; /* Adjust to change shape */
    transform: translate(-5%, -5%);
}

.good-job-box-content {
    position: relative;
    z-index: 1; /* Ensure the text is above the blob */
}
