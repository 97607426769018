.custom-tooltip {
  /* Semi transparent background */
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px;
  border: 1px solid #dddddd;
}

.custom-tooltip .label {
  font-weight: bold;
  color: #333333;
}

.custom-tooltip .intro,
.custom-tooltip .desc {
  color: #777777;
}

.container {
  /* padding top needs to be a few pixels, everything else must be 0*/
  padding: 0 0 0 0 !important;
  padding: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  box-sizing: content-box !important;
}


.fadeInAnimation {
  animation: fadeIn 0.5s ease-out forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
/* 
.loader {
  width: 150px;
  height: 150px;
  background-color: #022b46;
  border-radius: 50%;
  position: relative;
  box-shadow: 0 0 30px 4px rgba(0, 0, 0, 0.5) inset,
    0 5px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 45%;
  top: -40%;
  background-color: #bcd0de;
  animation: wave 5s linear infinite;
}
.loader:before {
  border-radius: 30%;
  background: rgb(187, 210, 223, 0.4);
  animation: wave 5s linear infinite;
}



@keyframes wave {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
} */

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #022b46 #022b46 transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #bcd0de #bcd0de;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 32px;
  height: 32px;
  border-color: #FFF #FFF transparent transparent;
  animation: rotation 1.5s linear infinite;
}
    
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
    

@keyframes pulse {
  0% {
      box-shadow: 0 0 0 0px rgba(255, 215, 0, 0.7);
  }
  70% {
      box-shadow: 0 0 0 10px rgba(255, 215, 0, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(255, 215, 0, 0);
  }
}
