.custom-tooltip {
  /* Semi transparent background */
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px;
  border: 1px solid #dddddd;
}

.custom-tooltip .label {
  font-weight: bold;
  color: #333333;
}

.custom-tooltip .intro,
.custom-tooltip .desc {
  color: #777777;
}

.container {
  /* padding top needs to be a few pixels, everything else must be 0*/
  padding: 0 0 0 0 !important;
  padding: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  box-sizing: content-box !important;
}

.loader {
  width: 50px;
  height: 50px;
  background-color: #022b46;
  border-radius: 50%;
  position: relative;
  box-shadow: 0 0 30px 4px rgba(0, 0, 0, 0.5) inset,
    0 5px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 45%;
  top: -40%;
  background-color: #bcd0de;
  animation: wave 5s linear infinite;
}
.loader:before {
  border-radius: 30%;
  background: rgb(187, 210, 223, 0.4);
  animation: wave 5s linear infinite;
}



@keyframes wave {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}